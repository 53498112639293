.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
}

.content-method-items {
  color: #fff;
  margin-bottom: 20px;

  &-intern {
    margin-top: 20px;
  }

  &-legend {
    color: #007eff;
    font-size: 19px;
    font-weight: 500;
    line-height: 41px;
  }

  h2 {
    font-size: 43px;
    font-weight: 800;
    line-height: 1.2em;
  }
}

.slider-container {
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-out;
}

.slide {
  flex: 0 0 auto;
  width: 20%; /* Ajuste conforme necessário */
  padding: 10px;
}

.slide img {
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .slide {
    width: 70%; /* Mostra 2 slides por vez em dispositivos móveis */
  }
}
