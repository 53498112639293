.content-guarantee {
  background-color: #000;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 30px;
  position: relative;
  color: #ffffff95;
  font-family: "Hanken Grotesk", Sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.5em;
}

.image {
  width: 100%;
  height: 600px;
}

@media (max-width: 768px) {
  .content-guarantee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    //height: 00px;
    // width: 100%;
    padding: 0px 30px;
  }
  .content-context {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    //padding: 0px 150px 0px 0px;
  }
  .image {
    width: 110%;
    height: 400px;
  }
}
