.content-lp-project-apresentation {
  position: relative;
  background-color: #000;
  //background-image: url("../../../../public/logo-code-start.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  transition: background 0.3s;
  margin: 0;
  padding: 0;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-alert {
    display: inline-block;
    border: 1px solid #444;
    border-radius: 50px;
    color: #ccc;
    padding: 0.25rem 0.5rem;
    background-color: #444;
    margin-top: 20px;
    width: 85%;
    font-size: 0.8rem;
  }

  &-container {
    position: relative;
    z-index: 2;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &-product-name {
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //background-color: #ffffff12;
    //border: solid 1px #a5a5a5;
    //border-radius: 5px;
  }

  &-box-texts {
    max-width: 800px;
    text-align: center;

    h1 {
      color: #fff;
      font-family: "Hanken Grotesk", Sans-serif;
      font-size: 48px;
      font-weight: 800;
      line-height: 1.1em;
    }

    p {
      color: #ccc;
      font-family: "Hanken Grotesk", Sans-serif;
      font-size: 25px;
      font-weight: 500;
      line-height: 1.3em;
    }
  }
}

@media (max-width: 768px) {
  .action-offer-apresentation {
    margin-top: 3rem;
  }
  .content-lp-project-apresentation {
    background-position: top center;
    padding: 0;
    min-height: 70vh;

    &-container {
      margin-top: 400px;
    }

    &-product-name {
      padding: 8px 25px;
      margin-top: -300px;
      border-width: 1px;
      margin-bottom: 28px;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 48px;
    }

    p {
      color: #fff;
      font-size: 18px;
      margin-bottom: 48px;
    }
  }
}

.optimized-svg {
  width: 70px;
  height: 70px;
  transform: translate3d(0, 0, 0);
}
