.video-container {
  position: relative;
  //width: 360px;
  height: 315px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  width: 68px;
  height: 48px;
  background: url("https://w7.pngwing.com/pngs/566/248/png-transparent-computer-icons-youtube-play-button-button-angle-text-triangle.png")
    no-repeat center center;
  background-size: contain;
}
