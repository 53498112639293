.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.contact-text-section {
  max-width: 60%;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
}

.contact-heading {
  font-size: 40px;
  margin-bottom: 40px;
  line-height: 60px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.contact-subtext {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.contact-whatsapp-button {
  background-color: #25d366;
  padding: 25px 35px;
  font-size: 28px;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 22px;
    padding: 20px 30px;
  }

  .contact-whatsapp-icon {
    margin-right: 10px;
  }
}

.contact-image-section {
  width: 50%;
  text-align: right;
  background-color: transparent;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    order: -1;
  }
}

.contact-image {
  max-width: 100%;
  border-radius: 8px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}
