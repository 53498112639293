.container1 {
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container1 .pricing1 {
  position: absolute;
  top: -15px;
  right: 50;
  background-color: #00bcd3;
  color: #fff;
  border-radius: 12px;
  padding: 5px 10px;
  font-weight: bold;
  width: 200px;
  height: 2rem;
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;

  @media (max-width: 768px) {
    width: 7rem;
    font-size: 12px;
    height: 1.5rem;
  }
}

.title-card-offer {
  font-size: 3rem;
  font-weight: bold;
  color: #00bcd3;
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
}
