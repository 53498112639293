.faq-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.question-list {
  width: 100%;
  border-radius: 5px;
  background-color: #181b20;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px;
}

@media screen and (max-width: 768px) {
  .question-list {
    gap: 32px;
    padding: 0;
  }
}
