.question-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px;
  box-sizing: border-box;
}

.question {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;

  cursor: pointer;
  transition: filter 0.3s;
}

.question:hover {
  filter: brightness(0.8);
}

.answer {
  color: #fff;
  font-size: 20px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .question-container {
    padding: 24px;
  }
  .question {
    font-size: 20px;
  }

  .answer {
    font-size: 16px;
  }
}
