.school_content_code-start {
    display: flex;
    justify-content: center;

    img{
        width: 50%;
    }
  }
@media screen and (max-width: 768px) {
    .school_content_code-start{
        img{
            width: 100%;
        }
    }
    
}