.line-offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line-offer:last-child {
  border-bottom: none;
}

.line-offer p,
.line-offer span {
  font-size: 18px;
}

@media (max-width: 768px) {
  .line-offer p,
  .line-offer span {
    font-size: 13px;
    font-weight: bold;
    max-width: 160px;
  }
}
